.input-wrapper {

  .input-label {

    &.as-placeholder {
      @apply text-gray text-opacity-50 -mt-4;
    }
  }

  .MuiInput-root {

    input.MuiInput-input {
      @apply bg-white;
      @apply border-gray-d9 rounded;
      @apply outline-none transition-all;
    }

    &::before, &::after {
      display: none;
    }

    .MuiAutocomplete-endAdornment {
      right: 4px;
    }
  }

  &.input-size-xs {

    .MuiInput-root .MuiInput-input, .MuiSelect-select {
      @apply px-2 py-1.5 text-xs;

      input {
        @apply text-xs;
      }
    }

    .input-label.as-placeholder {
      @apply top-7.5 left-2 text-xs;
    }
  }

  &.input-size-sm {

    .MuiInput-root .MuiInput-input, .MuiSelect-select {
      @apply px-4 py-2 text-sm;

      input {
        @apply text-sm;
      }
    }

    .MuiAutocomplete-root input {
      @apply text-sm;
    }

    .input-label.as-placeholder {
      @apply top-8 left-4 text-sm;
    }
  }

  &.input-size-md {

    .MuiInput-root .MuiInput-input, .MuiSelect-select {
      @apply px-5 py-2.5 text-base;

      input {
        @apply text-base;
      }
    }

    .input-label.as-placeholder {
      @apply top-9.5 left-5 text-base;
    }
  }

  .MuiSelect-select {
    border: 0;
    outline: none;
  }

  .MuiOutlinedInput-notchedOutline {
    display: none;
  }
}

.MuiMenu-paper.MuiPaper-root {
  @apply shadow-md;
}
