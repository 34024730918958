.MuiCheckbox-root {

  &.checkbox-blue-light {
    color: #ccc;

    &.Mui-checked,
    &.MuiCheckbox-indeterminate {
      //@apply text-blue-light;
    }
  }
}

.MuiRadio-root:not(.Mui-checked) {
  color: #ccc;
}

.MuiTooltip-popper .MuiTooltip-tooltip {
  background: #0B1C34;
  border-radius: 0;
  font-size: 0.75rem;
  max-width: 36rem;
  box-shadow: 0 2px 6px #0B1C3440;

  .MuiTooltip-arrow {
    color: #0B1C34;
  }
}

.MuiSwitch-root .MuiSwitch-switchBase {
  &:not(.Mui-checked) {
    color: #8694A5;

    & ~ .MuiSwitch-track {
      @apply bg-light;
      @apply opacity-100;
    }
  }
}

.MuiTabs-root {

  &.disabled {

    .MuiTabs-indicator {
      background: #ccc;
    }
  }

  .MuiTab-root {
    text-transform: initial;
    border-bottom: 1px solid #EBEBEB;

    &.Mui-selected {
      font-weight: bold;
    }
  }
}

.MuiAccordion-root {
  @apply px-4;
  @apply py-0;

  &.MuiAccordion-rounded {
    @apply shadow-md;
    @apply overflow-hidden;

    &:first-of-type {
      @apply rounded-tl-md;
      @apply rounded-tr-md;
    }

    &:last-of-type {
      @apply rounded-bl-md;
      @apply rounded-br-md;
    }
  }
}

.MuiSlider-root {

  &.readonly {

    .MuiSlider-thumb {

      &::before {
        @apply shadow-none;
      }
    }
  }

  &.size-xs {

    .MuiSlider-thumb {
      @apply w-3 h-3;
    }
  }

  &.slider-primary {
    @apply text-primary;

    &.readonly {
      color: #F6A734 !important;
    }

    .MuiSlider-rail {
      @apply text-light;
      @apply opacity-100;
      height: 1.5px;
    }

    .MuiSlider-thumb {

      &:hover, &.Mui-focusVisible {
        box-shadow: 0 0 0 8px rgba(#F6A734, 0.16);
      }

      &.Mui-active {
        box-shadow: 0 0 0 14px rgba(#F6A734, 0.16);
      }
    }
  }

  &.slider-success {
    @apply text-success;

    &.readonly {
      color: #A3CE5E !important;
    }

    .MuiSlider-rail {
      @apply text-light;
      @apply opacity-100;
      height: 1.5px;
    }

    .MuiSlider-thumb {

      &:hover, &.Mui-focusVisible {
        box-shadow: 0 0 0 8px rgba(#A3CE5E, 0.16);
      }

      &.Mui-active {
        box-shadow: 0 0 0 14px rgba(#A3CE5E, 0.16);
      }
    }
  }
}

.MuiList-root {
  padding: 0 !important;

  .MuiMenuItem-root {
    @apply border-b border-solid border-gray-eb px-4 py-2;
  }
}

.rdrDateRangePickerWrapper {

  .rdrDayNumber {
    top: 0;
  }

  .rdrDayToday .rdrDayNumber span:after {
    bottom: 0;
  }
}
