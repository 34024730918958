.data-table {

  .MuiTableHead-root .MuiTableCell-head {
    @apply font-bold whitespace-nowrap;
  }

  .MuiTableBody-root .MuiTableCell-root {
    @apply whitespace-nowrap;
  }

  .MuiTableCell-root.MuiTableCell-paddingCheckbox {
    padding: 0 0.5rem !important;

    & ~ .MuiTableCell-root {
      padding-left: 0 !important;
    }
  }

  &.table-stripped {

    .MuiTableBody-root .MuiTableRow-root:nth-of-type(odd) {
      //@apply bg-gray-f4;
    }
  }

  &.table-xs {

    .MuiTableHead-root .MuiTableCell-head {
      @apply text-xs;
      @apply px-4 pt-4 pb-3;
    }

    .MuiTableBody-root .MuiTableCell-root {
      @apply text-xs px-4 py-1.5;
    }
  }

  .MuiTablePagination-root {
    @apply font-primary;
    @apply border-0;

    .MuiTablePagination-selectLabel,
    .MuiInputBase-root {
      @apply hidden;
    }
  }
}

.MuiTablePagination-displayedRows {
  @apply text-sm;
}
