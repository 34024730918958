@import url("~@fortawesome/fontawesome-free/css/all.min.css");
@import url("~@fortawesome/fontawesome-free/css/v4-shims.css");
@import url("~react-date-range/dist/theme/default.css");
@import url("~react-date-range/dist/styles.css");
@import url("/src/assets/scss/index.scss");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {
    .flex-center {
      @apply flex items-center justify-center;
    }
  }
}
